export default {
    components: {
    },
    data() {
        return {
            banner: [
                require('../../../assets/index/banner.jpg'),
                require('../../../assets/index/banner_EN.jpg')
            ]
        }
    },
    methods: {
        toDetail(type) {
            if (this.$route.name[0] == 'GreIndex') {
                this.$router.push({ name: 'Gre' + type })
            } else {
                this.$router.push({ name: 'GrePractice' + type })
            }
        }
    },
}